<template>
  <div>
    <footer class="footer">
      <!-- <div class="footer-web">
        <div class="icon-content">
          <div class="footer-icon">
            <div class="pic">
              <img class="img1" src="~@/assets/img/foot01.png" />
            </div>
            <div class="txt">
              <span class="footer-icon-text1">正品保障</span>
              <span class="footer-icon-text2">正品保障、提供发票</span>
            </div>
          </div>
          <div class="footer-icon">
            <div class="pic">
              <img class="img2" src="~@/assets/img/foot02.png" />
            </div>
            <div class="txt">
              <span class="footer-icon-text1">急速物流</span>
              <span class="footer-icon-text2">如约送货、送货入户</span>
            </div>
          </div>
          <div class="footer-icon">
            <div class="pic">
              <img class="img3" src="~@/assets/img/foot03.png" />
            </div>
            <div class="txt">
              <span class="footer-icon-text1">售后无忧</span>
              <span class="footer-icon-text2">30天包退、365天包换</span>
            </div>
          </div>
          <div class="footer-icon">
            <div class="pic">
              <img class="img4" src="~@/assets/img/foot04.png" />
            </div>
            <div class="txt">
              <span class="footer-icon-text1">帮助中心</span>
              <span class="footer-icon-text2">您的购物指南</span>
            </div>
          </div>
          <div class="footer-icon">
            <div class="pic">
              <img class="img5" src="~@/assets/img/foot05.png" />
            </div>
            <div class="txt">
              <span class="footer-icon-text1">省心购</span>
              <span class="footer-icon-text2">专注好服务</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="service-intro">
        <div class="servece-type">
          <div
            class="servece-type-info"
            v-for="(guide, index) in guideArr"
            :key="index"
          >
            <ul>
              <li v-for="(item, index) in guide" :key="index">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright">
        <!-- <p>
          Copyright© 2020-2028云南大通汇电子科技有限公司版权所有 |
          滇公网安备 32010202010066号 | 滇ICP备2021027988号
        </p>
        <p>
          经营证照| 网络文化经营许可证：滇网文〔2018〕10580-203号|
          互联网违法和不良信息举报邮箱：kfpt-yy@cndth.com|
          举报电话：02566996699-865948
        </p>
        <p>
          本网站直接或间接向消费者推销商品或者服务的商业宣传均属于“广告”（包装及参数、售后保障等商品信息除外）
        </p> -->
        <p>
          备案号： 滇ICP备2023000453号-2
        </p>
        <p>
          云南恒投供应链管理有限公司公司版权所有
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      guideArr: [
        ['购物指南', '免费注册', '会员等级', '常见问题'],
        ['支付方式', '微信支付', '网银支付', '快捷支付', '货到付款'],
        ['物流配送', '运费政策', '物流配送服务', '签收验货', '物流查询'],
        ['售后服务', '退换货政策', '订单修改', '退换货申请', '我的发票'],
        ['商家服务', '合作招商', '广告服务', '商家帮助', '返服务市场']
      ],
      moreLink: [
        '关于我们',
        '联系我们',
        '联系客服',
        '合作招商',
        '商家帮助',
        '营销中心',
        '销售联盟',
        '校园社区',
        '风险监测',
        '隐私政策'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
/*****************************底 部 开 始*****************************/
.footer {
  width: 100%;
}
.icon-row {

  background-color: #e8e8e8;
  width: 100%;
  padding-top: 20px;

  .icon-content{
  width: var(--Width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .footer-icon {
    display: flex;
    align-items: center;

    .pic {
      display: flex;
      text-align: center;
      width: 50px;
      height: 50px;
      background: #ffb200;
      border-radius: 50%;
      opacity: 1;
     margin: 0px 10px 0px 0px;
      img{
        margin: auto;
      }
      .img1 {
        width: 27.5px;
        height: 31.31px;
      }
      .img2 {
        width: 29.77px;
        height: 23.82px;
      }
      .img3 {
        width: 22.77px;
        height: 24.83px;
      }
      .img4 {
        width: 12.95px;
        height: 23.15px;
      }
      .img5 {
        width: 17.31px;
        height: 28.96px;
      }
    }
    .txt {
      display: flex;
      flex-direction: column;
      .footer-icon-text2 {
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
      }
      .footer-icon-text1 {
        height: 18px;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #333333;
      }
    }
  }

}
}

.service-intro {
  background-color: #e8e8e8;
  width: 100%;
  border-top: 1px solid #e8e8e8;
}
.servece-type {
  margin: 15px auto;
  padding: 20px 0px;
  border-top: 2px solid #d1d1d1;
  width: var(--Width);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.servece-type-info {
  color: #666666;
}
.servece-type-info ul {
  list-style: none;
}
.servece-type-info li {
  font-size: 14px;
  cursor: pointer;
  line-height: 26px;
}
.servece-type-info li:first-child {
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
}
.copyright {
  width: 100%;
  color: #666;
  line-height: 30px;
  text-align: center;
}
.copyright a {
  color: #232323;
  font-size: 20px;
}
/*****************************底 部 结 束*****************************/
</style>
