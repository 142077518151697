export const SYS_TYPES = {
  // 处理权限
  HANDLER_PERMISSION: 'HANDLER_PERMISSION',
  // 设置登录
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
  // 退出登录
  LOGIN_OUT: 'LOGIN_OUT',
  // 菜单收缩状态
  SET_COLLAPSED: 'SET_COLLAPSED'
}
