<template>
  <!--微信支付页面-->
  <div class="card-buy-weixin" v-show="show">
    <div class="close-xx" @click="zfclose">
      <img src="~@/assets/img/icon_close.png" />
    </div>
    <div class="top-title">
      <h3>
        订单提交成功，请尽快付款！
        <span
          >应付金额：<em class="red">{{ dataSource.payAmount }}</em
          >元</span
        >
      </h3>
      <h2><img src="~@/assets/img/cardpay_img-05.png" />微信支付</h2>
      <!--<p>距离二维码过期还剩<span class="red">36</span>秒，过期后请刷新页面重新获取二维码。</p>-->
      <p>温馨提示：微信支付二维码过期后关闭支付弹框，重新提交订单。</p>
    </div>
    <ul class="pay-img">
      <li>
        <i class="code-img">
          <!-- <img src="~@/assets/img/dth-app.png" /> -->
          <div id="qrcode" ref="qrcode"></div>
        </i>
        <ul class="code-bottom">
          <li class="saoma-icon"><img src="~@/assets/img/saoma.png" /></li>
          <li class="right-txt">
            <h4>请使用微信扫一扫<span>扫描二维码支付</span></h4>
          </li>
          <div class="clearfix"></div>
        </ul>
      </li>
      <li class="right-phone">
        <img src="~@/assets/img/pc-icon-phone-bg.png" />
      </li>
      <div class="clearfix"></div>
    </ul>
    <div class="clearfix"></div>
  </div>
</template>
<script>
import QRCode from 'qrcode2'
import { wxPay, getPayStatus } from '@/api/order.js'
export default {
  props: {
    dataSource: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      show: '',
      img: '',
      timer: ''
    }
  },
  methods: {
    // 关闭微信支付弹框
    zfclose () {
      this.show = false
      clearInterval(this.timer)
    },
    // 生成二维码
    qrcodeScan (url) {
      // 多次生成时，怎么清空前一个二维码
      var elem = document.getElementById('qrcode')
      elem.innerHTML = ''
      const qrcode = new QRCode('qrcode', {
        width: 200, // 二维码宽度
        height: 200, // 二维码高度
        text: url
      })
      this.img = qrcode
    },
    // 微信支付
    wxPay (ids) {
      var params = {
        objects: ids
      }
      wxPay(params)
        .then((res) => {
          this.qrcodeScan(res.data.codeUrl)
          this.show = true
          this.timer = setInterval(() => {
            // 定时器操作
            this.wxPayStatus(res.data.paySn)
          }, 2000)
        })
        .finally(() => {})
    },
    // 定时器获取微信支付状态
    wxPayStatus (sn) {
      var params = {
        paySn: sn
      }
      getPayStatus(params)
        .then((res) => {
          if (res.data === 'SUCCESS') {
            clearInterval(this.timer)
            this.$router.push({
              path: '/payDone',
              query: {}
            })
          }
        })
        .finally(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
  /*微信支付黑色遮罩*/
.card-buy-weixin {
  width: 860px;
  background-color: #fff;
  position: fixed;
  top: 60px;
  left: 0%;
  right: 0%;
  margin: auto;
  -webkit-box-shadow: 0px 0px 15px #e5e5e5;
  -moz-box-shadow: 0px 0px 15px #6b6a6a;
  box-shadow: 0px 0px 15px #6b6a6a;
  z-index: 1001;
}
.close-xx {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}
.close-xx img {
  width: 13px;
  height: 13px;
}
.top-title {
  margin: 60px 30px;
}
.top-title h3 {
  width: 98%;
  font-size: 14px;
  color: #999999;
  margin: 0 auto;
  position: relative;
}
.top-title h3 span {
  display: inline-block;
  font-size: 20px;
  color: #666666;
  position: absolute;
  right: 50px;
  bottom: 0;
}
.top-title span .red {
  color: red;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}
.top-title h2 {
  font-size: 22px;
  color: #222222;
  margin-top: 30px;
  text-align: center;
}
.top-title h2 img {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  margin-right: 10px;
}
.top-title p {
  font-size: 18px;
  color: #666666;
  margin-top: 40px;
  text-align: center;
}
.top-title p span {
  color: red;
  font-weight: bold;
}
.pay-img {
  margin: 50px auto;
  width: 80%;
}
.pay-img li {
  float: left;
  width: 50%;
  text-align: center;
}
.pay-img .code-img {
  width: 200px;
  height: 200px;
  margin: 20px auto;
  display: block;
  overflow: hidden;
}
.pay-img .code-img img {
  width: 100%;
  height: 100%;
}
.pay-img .code-bottom {
  width: 53%;
  height: 50px;
  background-color: #fd5c63;
  margin: 0 auto;
}
.pay-img .code-bottom .saoma-icon {
  width: 40%;
}
.pay-img .code-bottom .saoma-icon img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
.pay-img .code-bottom .right-txt {
  width: 55%;
  text-align: left;
  line-height: 18px;
}
.pay-img .code-bottom .right-txt h4 {
  color: #fff;
  font-weight: bold;
  margin-top: 7px;
  font-size: 12px;
}
.pay-img .right-phone {
  width: 249px;
  height: 341px;
  display: block;
  overflow: hidden;
}
.pay-img .right-phone img {
  width: 100%;
}
ul li {
  list-style-type: none;
}
</style>
