// import localStorage from 'store'

export function formatterDate (date, format) {
  var year = date.getFullYear()
  var month = date.getMonth() + 1 // 月份是从0开始的
  var day = date.getDate()
  var hour = date.getHours()
  var min = date.getMinutes()
  var sec = date.getSeconds()
  var preArr = Array.apply(null, Array(10)).map(function (elem, index) {
    return '0' + index
  }) /// /开个长度为10的数组 格式为 00 01 02 03
  var newTime = format
    .replace(/YY/g, year)
    .replace(/MM/g, preArr[month] || month)
    .replace(/DD/g, preArr[day] || day)
    .replace(/hh/g, preArr[hour] || hour)
    .replace(/mm/g, preArr[min] || min)
    .replace(/ss/g, preArr[sec] || sec)

  return newTime
}

export function formattimeT (time) {
  if (!time) {
    return '--'
  } else {
    return time.slice(0, 10) + ' ' + time.slice(11, 20)
  }
}

/**
 * 防抖
 * @param {*} fn
 * @param {*} t
 */
export const Debounce = (fn, t) => {
  const delay = t || 500
  let timer
  return function () {
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, delay)
  }
}

/*
 * 函数节流
 */
export const Throttle = (fn, t) => {
  let last
  let timer
  const interval = t || 500
  return function () {
    const args = arguments
    const now = +new Date()
    if (last && now - last < interval) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(this, args)
      }, interval)
    } else {
      last = now
      fn.apply(this, args)
    }
  }
}

export const getRouteTitleHandled = (route) => {
  const router = { ...route }
  const meta = { ...route.meta }
  let title = ''
  if (meta.title) {
    if (typeof meta.title === 'function') {
      title = meta.title(router)
    } else title = meta.title
  }
  meta.title = title
  router.meta = meta
  return router
}

export function formatDate (date, fmt) {
  date = new Date(date)
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}

export function getDate () {
  var d = new Date()
  var year = d.getFullYear()
  var month = d.getMonth()
  month = month + 1 > 12 ? 1 : month + 1
  month = month > 9 ? month : '0' + month.toString()
  var day = d.getDate()
  day = day > 9 ? day : '0' + day.toString()
  var hour = d.getHours()
  hour = hour > 9 ? hour : '0' + hour.toString()
  var minute = d.getMinutes()
  minute = minute > 9 ? minute : '0' + minute.toString()
  var second = d.getSeconds()
  second = second > 9 ? second : '0' + second.toString()
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

/**
 *根据name 获取字典对应的文字
 * @param {*} type 字典类型
 * @param {*} name 字典类型name
 */
export const getDictionaryName = (type, value) => {
  const dictionaryData = JSON.parse(sessionStorage.getItem('SH_DIC')) || {}
  var dictionaryList = dictionaryData[type] || []
  var list = []
  for (var x in dictionaryList) {
    const a = { value: '', text: '' }
    a.value = x
    a.text = dictionaryList[x]
    list.push(a)
  }
  dictionaryList = list
  const rs = dictionaryList.filter((item) => {
    return item.value === value
  })
  return rs[0] || { text: '---', value: '---' }
}

/**
 * 根据字典类型 获取字典集合
 * @param {*} type
 */
export const getDictionary = (type) => {
  const dictionaryData = JSON.parse(sessionStorage.getItem('SH_DIC')) || {}
  var dictionaryList = dictionaryData[type] || []
  var list = []
  for (var x in dictionaryList) {
    const a = { value: '', text: '' }
    a.value = x
    a.text = dictionaryList[x]
    list.push(a)
  }
  dictionaryList = list
  return dictionaryList
}

/**
 * 获取系统字典
 * @param {*} type
 * @param {*} value
 */
export const getSysDictionaryName = (type, value) => {
  const dictionaryData = JSON.parse(sessionStorage.getItem('SH_DIC')) || {}
  const dictionaryList = dictionaryData[type] || []
  const rs = dictionaryList.filter((item) => {
    return item.code === value
  })
  return rs[0] || { text: '---', value: '---' }
}

/**
 * 根据字典类型 获取字典集合
 * @param {*} type
 */
export const getSysDictionary = (type) => {
  const dictionaryData = JSON.parse(sessionStorage.getItem('SH_DIC')) || {}
  const dictionaryList = dictionaryData[type] || []
  return dictionaryList
}

/**
 * 获取文件类型
 * @param {*} type
 */
export const getFileType = (name) => {
  const a = name.split('.')
  const b = a.length
  if (a[b - 1] === 'png' || a[b - 1] === 'jpeg' || a[b - 1] === 'gif' || a[b - 1] === 'jpg' || a[b - 1] === 'mpeg' || a[b - 1] === 'mpg') {
    return '图片'
  } else if (a[b - 1] === 'pdf') {
    return 'pdf'
  } else if (a[b - 1] === 'docx' || a[b - 1] === 'doc') {
    return 'word'
  } else if (a[b - 1] === 'xlsx' || a[b - 1] === 'xls') {
    return 'excle'
  } else if (a[b - 1] === 'pptx' || a[b - 1] === 'ppt') {
    return 'ppt'
  } else {
    return '其他文件'
  }
}
export const getParams = () => {
  return JSON.parse(sessionStorage.getItem('routerParams'));
}

// 单独包税标识显示
export const taxIncluded = (val) => {
  // console.log(val)
  if (val) {
    return '(包税)'
  } else {
    return ''
  }
}

// 包税标识显示和税费显示
export const taxIncludedNum = (good) => {
  // console.log(goods)
  var num = (good.price * good.taxRate) / 100;
  if (good.taxIncluded) {
    return '包税';
  } else {
    return '¥' + num.toFixed(2);
  }
};

// 包税价格
export const taxPrice = (good) => {
  // console.log(goods)
  if (good.taxIncluded) {
    return good.price + (good.price * good.taxRate) / 100;
  } else {
    return good.price;
  }
}

// 总价计算
export const totalPrice = (goods) => {
  // console.log(goods)
  goods.goodsList.forEach((item, index) => {
    item.tax = (item.price * item.taxRate) / 100 * item.quantity;
    item.tax = parseFloat(item.tax.toFixed(2));
  });
  var total = 0;
  var num = 0;
  var amount = goods.amount;
  goods.goodsList.forEach((item, index) => {
    if (item.taxIncluded) {
      num = num + item.tax
    }
  });
  total = amount + num;
  return total.toFixed(2);
}

// 总税费计算
export const totalTax = (goods) => {
  // console.log(goods)
  goods.goodsList.forEach((item, index) => {
    item.tax = (item.price * item.taxRate) / 100 * item.quantity;
    item.tax = parseFloat(item.tax.toFixed(2));
  });
  var total = 0;
  var num = 0;
  var tax = goods.tax;
  goods.goodsList.forEach((item, index) => {
    if (item.taxIncluded) {
      num = num + item.tax
    }
  });
  total = tax - num;
  return total.toFixed(2);
}
