<template>
	<Select
		:placeholder="placeholder"
		v-model="selectValue"
		:disabled="disabled"
		@on-change="handleChange"
	>
		<Option v-for="(item, key) in list" :key="key" :value="item.code">
			{{ item.name}}
		</Option>
	</Select>
</template>
<script>
import { getSysDictionary } from '@/utils/common.js';
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请输提示占位符'
    },
    dicName: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    exclude: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      list: {},
      selectValue: this.value
    };
  },
  watch: {
    value (val) {
      if (val !== this.selectValue) {
        this.selectValue = val;
      }
    }
  },
  created () {
    const rs = getSysDictionary(this.dicName);
    console.log(Object.keys(rs))
    Object.keys(rs).forEach(item => {
      const flag = this.exclude.some(val => {
        return item === val;
      });
      if (!flag) {
        this.list[item] = rs[item];
      }
    });
  },
  methods: {
    handleChange (value) {
      console.log(value)
      this.$emit('change', !value ? '' : value);
    }
  }
};
</script>
