<template>
  <Cascader
    :data="regionArr"
     placeholder="请选择关区/口岸"
    :disabled="cascaderDisabled"
    v-model="cascaderArr"
    @on-change="onChangeCascader"
  ></Cascader>
</template>
<script>
export default {
  name: 'RegionCascader',
  data () {
    return {
      regionArr: [],
      queryParams: {
        cusCode: '',
        org: ''
      },
      cascaderArr: [],
      cascaderDisabled: false,
      roleLength: 0
    }
  },
  mounted () {
    setTimeout(() => {
      const orgCode = JSON.parse(sessionStorage.getItem('userInfo')).orgCode
      const CIQ_ARRY = JSON.parse(sessionStorage.getItem('SYS_DICTIONARY')).CIQLABEL
      const ORG_ARRY = JSON.parse(sessionStorage.getItem('SYS_DICTIONARY')).ORGANIZATIONLABEL
      this.roleLength = orgCode.length
      const regionData = []
      if (JSON.parse(sessionStorage.getItem('REGION_DATA')) === undefined) {
        if (this.roleLength === 2) {
        // 超级管理员
          CIQ_ARRY.forEach((item, index) => {
            const rsOrgArry = ORG_ARRY.filter(orgItem => {
              return orgItem.value.includes(item.value)
            })
            const ciqItem = { ...item }
            ciqItem.children = [...rsOrgArry]
            regionData.push(ciqItem)
          })
        } else if (this.roleLength === 4) {
        // 关区管理员
          const rsCiq = CIQ_ARRY.filter(item => {
            return orgCode.includes(item.value)
          })
          const rsOrgArry = ORG_ARRY.filter(orgItem => {
            return orgItem.value.includes(orgCode)
          })
          const ciqItem = { ...rsCiq[0] }
          ciqItem.children = [...rsOrgArry]
          regionData.push(ciqItem)
          this.cascaderArr = [ciqItem.value]
          this.queryParams.cusCode = ciqItem.value
        } else if (this.roleLength === 6) {
        // 互市管理员
          const rsCiq = CIQ_ARRY.filter(item => {
            return orgCode.includes(item.value)
          })
          const ciqItem = { ...rsCiq[0] }
          const rsOrgArry = ORG_ARRY.filter(item => {
            return orgCode === item.value
          })
          const orgItem = [...rsOrgArry]
          ciqItem.children = [...orgItem]
          regionData.push(ciqItem)
          const arr = [ciqItem.value, orgItem[0].value]
          this.cascaderArr = arr
          this.cascaderDisabled = true
          this.queryParams.cusCode = this.cascaderArr[0]
          this.queryParams.org = this.cascaderArr[1]
        }
      }
      this.regionArr = regionData
      // console.log(this.queryParams)
      // console.log(JSON.stringify(regionData))
      // this.$emit('onInitChange', this.queryParams)
    }, 500)
  },
  methods: {
    init () {
      if (JSON.parse(sessionStorage.getItem('userInfo')).orgCode.length !== 6) {
        this.cascaderArr = []
      }
    },
    /**
     * 选择互市
     */
    onChangeCascader (val) {
      const length = val.length
      if (this.roleLength === 4 && length === 0) {
        this.cascaderArr = [this.regionArr[0].value]
        this.queryParams.cusCode = this.cascaderArr[0]
        this.queryParams.org = ''
        this.$emit('onChange', this.queryParams)
        return
      }
      switch (length) {
        case 0:
          this.queryParams.cusCode = ''
          this.queryParams.org = ''
          break

        case 1:
          this.queryParams.cusCode = val[0]
          this.queryParams.org = ''
          break

        case 2:
          this.queryParams.cusCode = val[0]
          this.queryParams.org = val[1]
          break
      }
      this.$emit('onChange', this.queryParams)
    }
  }
}
</script>
