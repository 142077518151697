import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import animate from 'animate.css';
import axios from 'axios';
import localStorage from 'store';
import BackHeader from '@/components/BackHeader';
import RangeDatePicker from '@/components/RangeDatePicker';
import RegionCascader from '@/components/RegionCascader.vue';
import Region from '@/components/Region.vue';
import WxPay from '@/components/WxPay.vue';
import ListPages from '@/components/ListPages.vue';
import UploadMe from '@/components/UploadMe.vue';
import ViewUI from 'view-design';
import SelectCom from '@/components/SelectCom.vue';
// iview自定义主题
import '@/assets/style/index.less';
import 'view-design/dist/styles/iview.css';
import vRegion from 'v-region';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 图片预览

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.use(ElementUI);
Vue.config.productionTip = false;

Vue.component('BackHeader', BackHeader);
Vue.component('RangeDatePicker', RangeDatePicker);
Vue.component('RegionCascader', RegionCascader);
Vue.component('Region', Region);
Vue.component('WxPay', WxPay);
Vue.component('UploadMe', UploadMe);
Vue.component('ListPages', ListPages);
Vue.component('SelectCom', SelectCom);
Vue.use(animate);
Vue.use(Viewer);
Vue.use(ViewUI);
Vue.use(vRegion);

Vue.prototype.$size = window.innerWidth <= 1440 ? 'small' : 'small';
Vue.prototype.$axios = axios;
Vue.prototype.$localStorage = localStorage;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
