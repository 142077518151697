import Vue from 'vue'
import Vuex from 'vuex'
import sys from './modules/sys.js'
import { getRouteTitleHandled } from '../utils/common'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sys
  },
  state: {
    // 这里放全局参数
    fileData: { fileType: 1004, project: 'PORT', password: 'heihei' }, // 上传文件接口参数
    tagNavList: sessionStorage.getItem('tagNavList')
      ? JSON.parse(sessionStorage.getItem('tagNavList'))
      : []
  },
  mutations: {
    addTag (state, route) {
      const { name, path, meta, params, query } = getRouteTitleHandled(route)
      const newList = [...state.tagNavList]
      // 用于更新同一个页面最后一次传参
      newList.forEach((item, index) => {
        if (item.path === path) {
          newList.splice(index, 1, { name, path, meta, params, query })
        }
      })
      if (newList.findIndex((item) => item.path === path) >= 0) {
        state.tagNavList = newList
      } else {
        if (
          name !== 'login' &&
          name !== '401' &&
          name !== '404' &&
          name !== '505'
        ) {
          state.tagNavList.push({ name, path, meta, params, query })
        }
      }
      sessionStorage.setItem('tagNavList', JSON.stringify(state.tagNavList))
    },
    deleteTag (state, route) {
      const index = state.tagNavList.findIndex(
        (tagNavItem) => tagNavItem.path === route.path
      )
      if (index >= 0) {
        state.tagNavList.splice(index, 1)
      }
      sessionStorage.setItem('tagNavList', JSON.stringify(state.tagNavList))
    }
  },
  actions: {
    addTag ({ commit }, route) {
      commit('addTag', route)
    },
    deleteTag ({ commit, state }, route) {
      return new Promise((resolve) => {
        commit('deleteTag', route)
        resolve([...state.tagNavList])
      })
    }
  }
})
