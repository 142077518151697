import Vue from 'vue'
import VueRouter from 'vue-router'
import { LoadingBar } from 'view-design'
// import localStorage from 'store'
import store from '../store'
import myInfoLayout from '@/layout/myInfoBaseLayout.vue'

Vue.use(VueRouter)
const routes = [
  { path: '/', redirect: { path: '/index' } },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/Index.vue'),
    meta: {
      title: '首页',
      keepAlive: true
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company/Index.vue'),
    meta: {
      title: '公司简介',
      keepAlive: true
    }
  },
  {
    path: '/goodsList',
    name: 'goodsList',
    component: () => import('@/views/GoodsList.vue'),
    meta: {
      title: '商品列表'
    }
  },
  {
    path: '/store',
    name: 'store',
    component: () => import('@/views/Store.vue'),
    meta: {
      title: '店铺'
    }
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('@/views/GoodsDetail.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: () => import('@/views/ShoppingCart.vue'),
    meta: {
      title: '加入购物车'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order.vue'),
    meta: {
      title: '购物车'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/smsRegister',
    name: 'smsRegister',
    component: () => import('@/views/SmsRegister.vue'),
    meta: {
      title: '短信注册'
    }
  },
  {
    path: '/payDone',
    name: 'payDone',
    component: () => import('@/views/PayDone.vue'),
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/myInfo',
    name: 'myInfo',
    component: myInfoLayout,
    redirect: '/myInfo/myHome',
    children: [
      {
        path: '/myInfo/myHome',
        name: 'myHome',
        component: () => import('@/views/myInfo/myHome.vue'),
        meta: {
          isUseCache: false,
          title: '首页',
          keepAlive: true,
          isFrist: true
        }
      },
      {
        path: '/myInfo/myCarts',
        name: 'myAddress',
        component: () => import('@/views/myInfo/myCarts.vue'),
        meta: {
          isUseCache: false,
          title: '我的购物车',
          keepAlive: true,
          isFrist: true
        }
      },
      {
        path: '/myInfo/myOrder',
        name: 'myOrder',
        component: () => import('@/views/myInfo/myOrder.vue'),
        meta: {
          isUseCache: false,
          title: '我的订单',
          keepAlive: false,
          isFrist: true
        }
      },
      {
        path: '/myInfo/myOrderDetail',
        name: 'myOrderDetail',
        component: () => import('@/views/myInfo/myOrderDetail.vue'),
        meta: {
          isUseCache: false,
          title: '订单详情',
          keepAlive: false,
          isFrist: false
        }
      },
      {
        path: '/myInfo/myGoods',
        name: 'myGoods',
        component: () => import('@/views/myInfo/myGoods.vue'),
        meta: {
          isUseCache: false,
          title: '我买过的商品',
          keepAlive: true,
          isFrist: true
        }
      },
      {
        path: '/myInfo/myCollect',
        name: 'myCollect',
        component: () => import('@/views/myInfo/myCollect.vue'),
        meta: {
          isUseCache: false,
          title: '商品收藏',
          keepAlive: false,
          isFrist: true
        }
      },
      {
        path: '/myInfo/myAttention',
        name: 'myAttention',
        component: () => import('@/views/myInfo/myAttention.vue'),
        meta: {
          isUseCache: false,
          title: '店铺关注',
          keepAlive: false,
          isFrist: true
        }
      },
      {
        path: '/myInfo/myFooter',
        name: 'myFooter',
        component: () => import('@/views/myInfo/myFooter.vue'),
        meta: {
          isUseCache: false,
          title: '我的足迹',
          keepAlive: false,
          isFrist: true
        }
      },
      {
        path: '/myInfo/myComment',
        name: 'myComment',
        component: () => import('@/views/myInfo/myComment.vue'),
        meta: {
          isUseCache: false,
          title: '发表评论',
          keepAlive: false,
          isFrist: false
        }
      },
      {
        path: '/myInfo/myLogistics',
        name: 'myLogistics',
        component: () => import('@/views/myInfo/myLogistics.vue'),
        meta: {
          isUseCache: false,
          title: '查看物流',
          keepAlive: false,
          isFrist: false
        }
      },
      {
        path: '/myInfo/myRefund',
        name: 'myRefund',
        component: () => import('@/views/myInfo/myRefund.vue'),
        meta: {
          isUseCache: false,
          title: '退款/退货',
          keepAlive: false,
          isFrist: false
        }
      },
      {
        path: '/mySetting/mySetting',
        name: 'mySetting',
        component: () => import('@/views/mySetting/mySetting.vue'),
        meta: {
          isUseCache: false,
          title: '个人设置',
          keepAlive: false,
          isFrist: false
        }
      },
      {
        path: '/mySetting/securitySetting',
        name: 'securitySetting',
        component: () => import('@/views/mySetting/securitySetting.vue'),
        meta: {
          isUseCache: false,
          title: '安全设置',
          keepAlive: true,
          isFrist: true
        }
      },
      {
        path: '/mySetting/addressManage',
        name: 'addressManage',
        component: () => import('@/views/mySetting/addressManage.vue'),
        meta: {
          isUseCache: false,
          title: '地址管理',
          keepAlive: true,
          isFrist: true
        }
      }, {
        path: '/mySetting/bankManage',
        name: 'bankManage',
        component: () => import('@/views/mySetting/bankManage.vue'),
        meta: {
          isUseCache: false,
          title: '银行卡管理',
          keepAlive: true,
          isFrist: true
        }
      }
    ]
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401.vue'),
    meta: {
      title: '401'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/500.vue'),
    meta: {
      title: '500'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

var routeList = []
var routePahtList = []
router.beforeEach((to, from, next) => {
  LoadingBar.start()

  if (JSON.stringify(to.params) !== '{}') {
    sessionStorage.setItem('routerParams', JSON.stringify(to.params));
  }
  // 登陆判断
  if (sessionStorage.getItem('TOKEN')) {
    if (to.path !== 'login') {
      store.dispatch('addTag', to)
      if (to.meta.isFrist) {
        routeList = []
        routePahtList = []
      }
      var index = routeList.indexOf(to.meta.title)
      if (index !== -1) {
        // 如果存在路由列表，则把之后的路由都删掉
        routeList.splice(index + 1, routeList.length - index - 1)
      } else {
        routeList.push(to.meta.title)
      }
      var pathIndex = routePahtList.indexOf(to.path)
      if (pathIndex !== -1) {
        // 如果存在路由列表，则把之后的路由都删掉
        routePahtList.splice(
          pathIndex + 1,
          routePahtList.length - pathIndex - 1
        )
      } else {
        routePahtList.push(to.path)
      }
      to.meta.routeList = routeList
      to.meta.routePahtList = routePahtList
    }
    next()
  } else {
    if (to.path === '/login' || to.path === '/company' || to.path === '/401' || to.path === '/index' || to.path === '/goodsList' || to.path === '/goodsDetail' || to.path === '/register' || to.path === '/smsRegister' || to.path === '/store') {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.path }
      })
    }
  }
})

router.afterEach(() => {
  LoadingBar.finish()
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
