<template>
  <div class="layout-container">
    <div
      class="demo-upload-list"
      v-for="(item, index) in defaultList"
      :key="index"
    >
      <img :src="item.image" />
      <div class="demo-upload-list-cover">
        <Icon type="ios-eye-outline" @click.native="handleView(item)"></Icon>
        <Icon
          v-if="!disabled"
          type="ios-trash-outline"
          @click.native="handleRemove(item, index)"
        ></Icon>
      </div>
    </div>
    <Upload
      v-if="(defaultList.length == 0 && !multiple) || multiple"
      ref="upload"
      :show-upload-list="false"
      :default-file-list="defaultList"
      :action="uploadURL"
      :max-size="102400 * 2000"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-upload="onBefore"
      :multiple="multiple"
      :disabled="disabled"
      type="drag"
      :style="{ display: 'inline-block', width: imageWidth }"
    >
      <div
        :style="{
          width: imageWidth,
          height: imageWidth,
          lineHeight: imageWidth
        }"
        v-if="!disabled"
      >
        <Icon type="ios-camera" size="20"></Icon>
      </div>
    </Upload>
    <Modal title="图片" v-model="visible">
      <img :src="showUrl" v-if="visible" style="width: 100%" />
    </Modal>
  </div>
</template>

<script>
import { uploadFile } from "@/api/sys.js";
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    imageWidth: {
      type: String,
      default: "58px"
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      fileData: this.$store.state.fileData,
      uploadURL: "/",
      defaultList: [],
      showUrl: "",
      visible: false
    };
  },
  watch: {
    list(val) {
      this.defaultList = val;
    }
  },
  mounted() {
    // 编辑时给图片赋值
    this.defaultList = this.list || [];
  },
  methods: {
    handleView(item) {
      this.showUrl = item.image;
      this.visible = true;
    },
    onBefore(file) {
      if (file.size > 102400 * 2000) {
        this.$Notice.warning({
          title: "文件大小错误",
          desc: file.name + "文件大小不能超过200M"
        });
        return false;
      }
      uploadFile(file, "bt-store-admin").then((res) => {
        this.defaultList.push({ image: res.data });
        this.$emit("change", this.defaultList);
      });
      return false;
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 200M."
      });
    },
    handleError() {
      this.$Message.info("上传失败！");
    },
    handleRemove(item, index) {
      if (this.multiple) {
        this.defaultList.splice(index, 1);
      } else {
        // 删除文件
        this.defaultList = [];
      }
      this.$emit("change", this.defaultList);
    },
    handlePreview(file) {
      // 点击已上传的文件链接时的钩子
      location.href = file.url;
    }
  }
};
</script>

<style>
.demo-upload-list {
  display: inline-block;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 150px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
