<template>
  <div class="box">
    <div class="nav">
      <ul class="location">
        <li>
          <span>欢迎来到购物商城！</span>
          <!-- <Icon type="ios-pin-outline" class="icon" size="16"></Icon>
         <span class="icon">  {{ city }} </span> -->
          <!-- <Dropdown placement="bottom-start">
            <a href="javascript:void(0)">
              <Icon type="ios-pin-outline" class="icon"></Icon> {{ city }}
            </a>
            <DropdownMenu slot="list">
              <div class="city">
                <p v-for="(items, index) in cityArr" :key="index">
                  <span
                    v-for="(item, index) in items"
                    class="city-item"
                    :key="index"
                    @click="changeCity(item)"
                    >{{ item }}</span
                  >
                </p>
              </div>
            </DropdownMenu>
          </Dropdown> -->
        </li>
      </ul>
      <ul class="detail">
        <li class="first" v-show="!userInfo.name">
          你好，<router-link to="/login"
            ><span style="color: #33c400;">请登录</span> <Icon type="person"></Icon
          ></router-link>
          |<span class="text-color-red"
            ><router-link to="/register"
              >免费注册 <Icon type="person-add"></Icon></router-link
          ></span>
        </li>
        <!-- <li v-show="!!userInfo.name">
          <Dropdown>
            <p class="alias-p">
              <Avatar class="person-icon" icon="ios-person" size="small" />
              <span class="alias">{{ userInfo.name }} </span>
            </p>
            <DropdownMenu slot="list">
              <div class="my-page">
                <div class="my-info" @click="myInfo">
                  <Icon type="md-person" />
                  <p>个人中心</p>
                </div>
                <div class="sign-out" @click="signOutFun">
                  <Icon type="ios-log-out" />
                  <p>退出登录</p>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
        </li> -->
        <li v-show="!!userInfo.name">
          <p class="alias-p">
            <span class="alias">{{ userInfo.alias? userInfo.alias:userInfo.phone}} </span>
            <span class="alias" @click="signOutFun">退出登录 </span>
          </p>
        </li>
        <li >
            <!-- <Icon custom="iconfont icongerenzhongxin" size="14"/>  -->
            <span @click="goIndex(1)" style="cursor:pointer ;color: #999;">个人中心</span>

        </li>
        <li >
            <!-- <Icon custom="iconfont icongouwuche" size="14"/>  -->
            <span @click="goIndex(2)" style="cursor:pointer ;color: #999;"> 购物车</span>

        </li>
        <li >
            <!-- <Icon custom="iconfont iconwodedingdan" size="14"/> -->
            <span @click="goIndex(3)" style="cursor:pointer ;color: #999;">我的订单</span>

        </li>
        <li >
            <!-- <Icon custom="iconfont iconwodedingdan" size="14"/> -->
            <span @click="goIndex(4)" style="cursor:pointer ;color: #999;">公司简介</span>

        </li>
        <!-- <li>
          <router-link to="/index">
            首页</router-link
          >
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    if (sessionStorage.getItem('TOKEN')) {
      this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    }
  },
  data () {
    return {
      city: '云南',
      userInfo: { name: '' },
      cityArr: [['北京', '云南']]
    }
  },
  methods: {
    goIndex (index) {
      if (index === 1) {
        sessionStorage.setItem('active_name', '/mySetting/mySetting');
        sessionStorage.setItem('active_header_name', 'setting');
        this.$router.push({
          path: '/mySetting/mySetting',
          query: { }
        })
      }
      if (index === 2) {
        this.$router.push({
          path: '/shoppingCart',
          query: { }
        })
      }
      if (index === 3) {
        sessionStorage.setItem('active_name', '/myInfo/myOrder');
        sessionStorage.setItem('active_header_name', 'home');
        this.$router.push({
          path: '/myInfo/myOrder',
          query: { }
        })
      }
      if (index === 4) {
        this.$router.push({
          path: '/company',
          query: { }
        })
      }
    },
    changeCity (city) {
      this.city = city
    },
    myInfo () {
      this.$router.push('/myInfo/myOrder')
    },
    signOutFun () {
      this.userInfo = { name: '' }
      sessionStorage.setItem('isLogin', false)
      sessionStorage.setItem('TOKEN', '')
      // sessionStorage.setItem('storeId', '')
      sessionStorage.setItem('REFRESHTOKEN', '')
      sessionStorage.setItem('TOKENHEAD', '')
      sessionStorage.setItem('userInfo', '')
      sessionStorage.setItem('routerParams', '')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 35px;
  background-color: #f5f5f5;
}
.nav {
  margin: 0% auto;
  width: var(--Width);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nav ul {
  list-style: none;
}
.nav li {
  float: left;
  font-size: 12px;
  line-height: 35px;
  margin-right: 15px;
  font-weight: bold;
}
.nav a {
  text-decoration: none;
  color: #999999;
  padding-left: 15px;
  border-left: 1px solid #ccc;
  cursor: pointer;
}
.location a {
  border-left: none;
}
.nav a:hover {
  color: #33c400;
}
.location {
  color: #999999;
}
.icon {
  color: #33c400;
}
.first {
  color: #999999;
}
.first a:first-child {
  padding-left: 3px;
  border-left: none;
}
.city {
  padding: 10px 15px;
}
.city-item {
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
}
.city-item:hover {
  color: #33c400;
}
.person-icon {
  color: #fff;
  background-color: #f0cdb2;
}
.alias {
  margin-left: 5px;
  color: #999999;
}
.alias:hover {
  color: #33c400;
}
.shopping-cart-list {
  padding: 3px 15px;
}
.shopping-cart-box {
  margin: 8px 0px;
  margin-top: 15px;
  padding-bottom: 15px;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px #ccc dotted;
}
.shopping-cart-box:first-child {
  margin-top: 8px;
}
.shopping-cart-img {
  margin-right: 15px;
  width: 40px;
  height: 40px;
}
.shopping-cart-img img {
  width: 100%;
}
.shopping-cart-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 200px;
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
}
.shopping-cart-detail {
  color: #999999;
}
.shopping-cart-text {
  color: #ccc;
}
.go-to-buy {
  display: flex;
  justify-content: flex-end;
}
.shopping-cart-null {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cart-null-icon {
  font-size: 38px;
  margin-bottom: 15px;
}
.shopping-cart-null span {
  color: #999999;
  font-size: 12px;
  line-height: 16px;
}
.alias-p {
  cursor: pointer;
}
.my-page {
  padding: 3px 5px;
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-page a {
  margin: 0px;
  padding: 0px;
  border: none;
}
.my-info {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.my-info:hover {
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
}
.my-info i {
  font-size: 28px;
}
.my-info p {
  font-size: 12px;
}
.sign-out {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.sign-out:hover {
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
}
.sign-out i {
  font-size: 28px;
}
.sign-out p {
  font-size: 12px;
}
</style>
