/**
 * @author MadBeggar
 * 系统接口
 */
import http from '@/utils/http'
import idCard_http from '@/utils/idCard_http'
/**
 * 登录
 * @param {*} params
 */
export const login = (params) => {
  return http.post('/secure/user/login', params)
}

/**
 * 发送手机验证码
 * @param {*} params
 */
export const sendInfo = (params) => {
  return http.post('/auth/sms/sendCode', params)
}

// 验证手机验证码
export const smsvalidate = (params) => {
  return http.post('/auth/sms/validate', params)
}

/**
 * 注册
 * @param {*} params
 */
export const register = (params) => {
  return http.post('/secure/user/register', params)
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return http.get('/secure/user/current/roleMenu')
}

/**
 * 编辑用户
 */
export const editUser = (params) => {
  return http.put(`/secure/user/${params.id}`, params)
}

/**
 * 获取字典
 */
export const getDictAll = () => {
  return http.get('/dict/all');
}

// 文件上传
export const uploadFile = (file, name) => {
  const params = new FormData(); // 创建form对象
  params.append('file', file);
  params.append('project', name);
  return http.post('/file/upload', params, {
    headers: {
      'content-type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      const complete =
        (((progressEvent.loaded / progressEvent.total) * 100) | 0) + '%';
      console.log(complete);
    }
  });
};

// 批量上传文件
export const uploadBatchFiles = (file, name) => {
  const params = new FormData(); // 创建form对象
  params.append('file', file);
  params.append('project', name);
  return http.post('/file/upload', params, {
    onUploadProgress: progressEvent => {
      const complete =
        (((progressEvent.loaded / progressEvent.total) * 100) | 0) + '%';
      self.uploadMessage = '上传 ' + complete;
      console.log(complete);
    }
  });
};

// 实名认证
export const realNameAuthentication = (params) => {
  return idCard_http.post('/idenAuthentication', params)
}
