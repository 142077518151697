
<template>
  <div class="layout">
    <Layout>
      <Header>
        <Menu
          mode="horizontal"
          theme="dark"
          ref="HeaderMenu"
          :active-name="activeHeaderName"
          :open-names="openHeaderNames"
          @on-select="onSelectHeader"
          @on-open-change="onOpenChangeHeader"
          class="myMenu"
        >
          <div class="layout-header">
            <div class="layout-title" @click="goIndex()">我的商城</div>
            <div class="layout-nav">
              <MenuItem name="home"> 全部功能 </MenuItem>
              <MenuItem name="setting"> 账户设置 </MenuItem>
            </div>
          </div>
        </Menu>
      </Header>
      <Layout class="myMenu">
        <Sider hide-trigger :style="{ background: '#fff' }">
          <div class="sider-title">{{ menuTitle }}</div>
          <Menu
            accordion
            width="auto"
            ref="Menu"
            class="main-sider-menu"
            :active-name="activeName"
            :open-names="openNames"
            @on-select="onSelect"
            @on-open-change="onOpenChange"
          >
            <!---未缩略菜单-->
            <template v-for="(item, index) in menus">
              <MenuItem :name="item.url" :to="item.url" :key="index">
                <Icon :custom="'iconfont ' + item.icon" />
                {{ item.name }}
              </MenuItem>
            </template>
          </Menu>
        </Sider>
        <Layout>
          <Content
            :style="{ padding: '24px', minHeight: '600px', background: '#fff' }"
          >
            <Breadcrumb>
              <BreadcrumbItem
                :to="path(index)"
                v-for="(item, index) in $route.meta.routeList"
                :key="index"
              >
                {{ item }}
              </BreadcrumbItem>
            </Breadcrumb>
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: '',
      activeHeaderName: 'home',
      openNames: [],
      openHeaderNames: [],
      menuTitle: '全部功能',
      menus: [
        // {
        //   name: "我的购物车",
        //   icon: "",
        //   url: "/myInfo/myCarts",
        // },
        {
          name: '我的订单',
          icon: '',
          url: '/myInfo/myOrder'
        },
        // {
        //   name: "我买过的商品",
        //   icon: "",
        //   url: "/myInfo/myGoods",
        // },
        {
          name: '商品收藏',
          icon: '',
          url: '/myInfo/myCollect'
        },
        {
          name: '店铺关注',
          icon: '',
          url: '/myInfo/myAttention'
        },
        {
          name: '我的足迹',
          icon: '',
          url: '/myInfo/myFooter'
        }
      ]
    };
  },
  computed: {
    path () {
      return function (index) {
        return this.$route.meta.routePahtList[index];
      };
    }
  },
  mounted () {
    this.update();
  },
  methods: {
    goIndex () {
      this.$router.push({
        path: '/index',
        query: { }
      })
    },
    onSelectHeader (name) {
      const rs = name.split(',');
      sessionStorage.setItem('active_header_name', name);
      this.activeHeaderName = name;
      if (rs[1] === 'false') {
        // 没有二级菜单 清空 open names
        sessionStorage.setItem('menu_header_opennames', JSON.stringify([]));
      }
      this.update();
    },
    onOpenChangeHeader (name) {
      // 设置菜单展开子菜单的数组name
      this.openHeaderNames = name;
      sessionStorage.setItem('menu_header_opennames', JSON.stringify(name));
    },
    onOpenChange (name) {
      // 设置菜单展开子菜单的数组name
      this.openNames = name;
      sessionStorage.setItem('menu_opennames', JSON.stringify(name));
    },
    onSelect (name) {
      const rs = name.split(',');
      sessionStorage.setItem('active_name', name);
      this.activeName = name;
      if (rs[1] === 'false') {
        // 没有二级菜单 清空 open names
        sessionStorage.setItem('menu_opennames', JSON.stringify([]));
      }
      this.update();
    },
    update () {
      const openNames =
        JSON.parse(sessionStorage.getItem('menu_opennames')) || [];
      const activeName = sessionStorage.getItem('active_name');
      if (openNames || activeName) {
        this.openNames = openNames || [];
        this.activeName = activeName || 0;
        this.$nextTick(() => {
          this.$refs.Menu.updateOpened();
          this.$refs.Menu.updateActiveName();
        });
      }
      const openHeaderNames =
        JSON.parse(sessionStorage.getItem('menu_header_opennames')) || [];
      const activeHeaderName = sessionStorage.getItem('active_header_name');
      if (openHeaderNames || activeHeaderName) {
        this.openHeaderNames = openHeaderNames || [];
        this.activeHeaderName = activeHeaderName || 0;
        this.$nextTick(() => {
          this.$refs.HeaderMenu.updateOpened();
          this.$refs.HeaderMenu.updateActiveName();
        });
      }
      this.getMenus(this.activeHeaderName);
    },
    getMenus (name) {
      if (name === 'home') {
        console.log('首页');
        this.menuTitle = '全部功能';
        this.menus = [
          // {
          //   name: "我的购物车",
          //   icon: "",
          //   url: "/myInfo/myCarts",
          // },
          {
            name: '我的订单',
            icon: '',
            url: '/myInfo/myOrder'
          },
          // {
          //   name: "我买过的商品",
          //   icon: "",
          //   url: "/myInfo/myGoods",
          // },
          {
            name: '商品收藏',
            icon: '',
            url: '/myInfo/myCollect'
          },
          {
            name: '店铺关注',
            icon: '',
            url: '/myInfo/myAttention'
          },
          {
            name: '我的足迹',
            icon: '',
            url: '/myInfo/myFooter'
          }
        ];
      } else if (name === 'setting') {
        console.log('账户设置');
        this.menuTitle = '账户管理';
        this.menus = [
          {
            name: '个人设置',
            icon: '',
            url: '/mySetting/mySetting'
          },
          // {
          //   name: '安全设置',
          //   icon: '',
          //   url: '/mySetting/securitySetting'
          // },
          {
            name: '地址管理',
            icon: '',
            url: '/mySetting/addressManage'
          }
          // {
          //   name: '银行卡管理',
          //   icon: '',
          //   url: '/mySetting/bankManage'
          // }
        ];
      } else if (name === '3') {
        this.menuTitle = '消息';
        this.menus = [];
      }
    }
  }
};
</script>
<style  lang="scss" scoped>
.myMenu{
  width: var(--Width);
  margin: 0px auto;
}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
}
.layout-header {
  display: flex;
  justify-content: left;
  background: #33c400;
}
.layout-title {
  width: 150px;
  font-size: 29px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.layout-nav {
}
.ivu-layout-header {
  background: #33c400;
}
.ivu-menu-dark {
  background: #33c400;
}

.sider-title {
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  background: #33c400;
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  font-size: 18px;
  font-family: Source Han Sans CN;
  color: #ffffff;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu){
  color: #33c400 !important;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
  background: #33c400 !important;
}
.ivu-menu-light{
  height: 100% !important;
}
</style>
