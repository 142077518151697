import axios from "axios"; // 引入axios
import { Message } from "view-design";
import QS from "qs";
axios.defaults.baseURL = "https://idenauthen.market.alicloudapi.com";
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
const serve = axios.create({
  timeout: 10000 * 60 * 1000,
});

// 发送请求前 拦截处理
serve.interceptors.request.use((config) => {
  //公司
  //config.headers.Authorization = "APPCODE 3bcd33ec560542b9ba36380b218ca6e5";
  //个人
  config.headers.Authorization = "APPCODE 53f44ec1f85e4081a2fe823fa525a5be";
  //在发送请求之前做某件事
  if (config.method === "post") {
    config.data = QS.stringify(config.data);
  }
  return config;
});

// 请求响应时 拦截处理
serve.interceptors.response.use(
  (res) => {
    console.log('1111',res)
    const { data } = res;
    if (data.respCode === "0000") {
      return Promise.resolve(data);
    } else {
      data.message = "需实名认证，姓名与身份证号不一致！";
      Message.info(data.message);
      return Promise.reject(data);
    }
  },
  (error) => {
    const msg = handlerResponseErrorStatus(error);
    return Promise.reject(msg);
  }
);

/**
 * 处理请求响应状态码
 * @param {*} error 错误消息
 */
const handlerResponseErrorStatus = (status) => {
  const errorMsg = {};
  switch (status) {
    case 4000:
      errorMsg.message = "请求错误,参数不匹配";
      errorMsg.code = 4000;
      break;
    default:
      errorMsg.message = "未知错误";
      errorMsg.code = 9999;
  }

  return errorMsg;
};

export default serve;
