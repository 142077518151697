<template>
  <div id="app">
    <section id="main">
      <Header v-if="excludeHeaderRoutes.indexOf($route.name) == -1"></Header>

      <keep-alive>
          <router-view v-if="$route.meta.keepAlive">
          </router-view>
          <!-- 这里是会被缓存的视图组件，比如 Home！ -->
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive">
          <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
        </router-view>
      </section>
      <!-- 部分路由不应该包含这个Footer -->
      <Footer v-if="excludeFooterRoutes.indexOf($route.name) == -1"></Footer>

  </div>
</template>
<script>
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'
export default {
  name: 'App',
  data () {
    return {
      excludeHeaderRoutes: ['login', 'company'],
      excludeFooterRoutes: ['login', 'company']
    }
  },
  components: {
    Header,
    Footer
  },
  created () {
    this.$Message.config({
      top: 320,
      duration: 3
    })
  }
}
</script>
<style lang="scss">
@import url('./assets/iconfont/iconfont.css');
//动画时间
:root {
  --animate-duration: 0.5s !important;
  --animate-delay: 0.5s !important;
  --animate-repeat: 0.5 !important;
}
html,
body {
  margin: 0;
  min-height: 100vh;
  --Width: 1180px;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#main {
  flex: 1;
}
.ivu-card:hover {
  box-shadow: none !important;
  border-color: transparent !important;
}
.layout-list-pages {
  padding-top: 20px;
  text-align: right;
}
.layout-list-toolsbar {
  padding-bottom: 10px;
}
.layout-collapsed-sider {
  transition: all 0.2s ease-in-out;
}
.data-list-container {
  position: relative;
  height: 100%;
  padding: 0px 10px 0px 10px;
}
.price-color {
  color: #cc0212;
}
.status-color-n {
  //color: #cc0212;
  color: #f90;
}
.status-color-y {
  color: #09b10f;
}
.status-color-i {
  color: #2db7f5;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}

.pin-icon-load {
  animation: ani-spin 1s linear infinite;
}
@keyframes ani-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-table-header-bg {
  .ivu-table th {
    background-color: #fff;
  }
  .ivu-table:before {
    content: '';
    height: 0 !important;
  }
  .ivu-table tr:last-child td {
    border: none;
  }
}

.add-container {
  height: 100%;
  position: relative;
  .add-main {
    position: absolute;
    top: 70px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}

.search-auto-complete-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.ivu-select-item {
    cursor: auto;
  }
  &.ivu-select-item:hover {
    background-color: transparent;
  }
}

.search-auto-complete-list {
  height: 200px;
  overflow: scroll;
  .list-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.search-auto-complete-list,
.search-auto-complete-list-title {
  .list-title {
    flex: 1;
    text-align: center;
  }
}
.search-auto-complete-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.query-tools {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f5f5f5;
  text-align: right;
}
// .ivu-form-item {
//   margin-bottom: 10px !important;
// }
.ivu-form .ivu-form-item-label {
  font-size: 12px !important;
  margin-top: 2px;
}
.ivu-select-selected-value {
  font-size: 12px !important;
  margin-top: 2px;
}
.ivu-input {
  font-size: 12px !important;
}
.ivu-form-item-error-tip {
  // font-size: 5px !important;
  // padding-top: 0px !important;
}
.line-one {
  /* overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis; */
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  display: -webkit-box;
}
.line-two {
  /* overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis; */
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  display: -webkit-box;
}
#searchinput {
  background-color: #33c400 !important;
  border-radius: 20px !important;
  margin-top: 15px;
}
#searchinput .ivu-input {
  border-radius: 20px 40px 0px 20px !important;
  border: 2px solid #33c400 !important;
  height: 40px;
  padding-left: 20px;
}
#searchinput .ivu-input-search {
  border-radius: 0px 20px 20px 0px !important;
  background-color: #33c400 !important;
  border: 2px solid #33c400 !important;
  width:110px;
}
.empty {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 200px 0px;
  .content {
    display: flex;
    flex-direction: column;
    align-items:center;
    img {
      width: 200px;
      height: 200px;
    }
  }
}
.ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: #33c400 !important;
    background-color: #33c400 !important;
}
a:hover {
color:#33c400 !important;
text-decoration:none;
}
#logininput .ivu-input {
  border-radius: 5px !important;
  // border: 2px solid #33c400 !important;
  height: 40px;
  padding-left: 25px;
}
#logininput2 .ivu-input {
  border-radius: 5px 0px 0px 5px !important;
  // border: 2px solid #33c400 !important;
  height: 40px;
  padding-left: 25px;
}
#logininput .ivu-input-prefix i{
  height: 40px !important;
  line-height: 38px !important;
}
#logininput2 .ivu-input-prefix i{
  height: 40px !important;
  line-height: 38px !important;
}
.ivu-page-item-active {
    border-color: #33c400 !important;
}
.ivu-page-item-active a, .ivu-page-item-active:hover a {
    color: #33c400 !important;
}
.tax-gross-up{
  font-size: 12px;
  color: #ff3333;
}
</style>
