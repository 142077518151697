import axios from 'axios' // 引入axios
import { Message } from 'view-design'
// import localStorage from 'store'
import store from '@/store'
import { SERVER_URL, SERVER_CODE } from '@/config/api-config'
// import router from '@/router'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = SERVER_URL.DEV_URL
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = SERVER_URL.PRO_URL
}
// axios.defaults.baseURL = '/api/'

const serve = axios.create({
  timeout: 10000 * 60 * 1000,
  responseType: 'json'
})

// 发送请求前 拦截处理
serve.interceptors.request.use((config) => {
  // 除登录外的接口 添加token
  const tokenHead = sessionStorage.getItem('TOKENHEAD')
  const token = sessionStorage.getItem('TOKEN')
  if (config.url !== '/login') {
    config.headers.Authorization = `${tokenHead}${token}`
  }
  return config
})

// 请求响应时 拦截处理
serve.interceptors.response.use(
  (res) => {
    // console.log(res)
    const { data } = res
    // 处理除code等于200外的异常 目前的接口无法通过http status code 来判断后台异常
    if (data.code === SERVER_CODE.CODE_200) {
      return Promise.resolve(data)
    } else {
      handlerJumpErroPage(data.code)
      if (data.message === '用户名已经被注册') {
        data.message = '手机号已经被注册'
      }
      Message.error(data.message);
      return Promise.reject(data)
    }
  },
  (error) => {
    const msg = handlerResponseErrorStatus(error)
    return Promise.reject(msg)
  }
)

/**
 * 处理请求响应状态码
 * @param {*} error 错误消息
 */
const handlerResponseErrorStatus = (status) => {
  const errorMsg = {}
  switch (status) {
    case 400:
      errorMsg.message = '请求错误,参数不匹配'
      errorMsg.code = 400
      break

    case 401:
      errorMsg.message = '未授权，请登录'
      errorMsg.code = 400
      break

    case 403:
      errorMsg.message = '拒绝访问'
      errorMsg.code = 403
      break

    case 404:
      errorMsg.message = '请求地址找不到: '
      errorMsg.code = 404
      break
    case 405:
      errorMsg.message = '请求方式不支持: '
      errorMsg.code = 404
      break
    case 408:
      errorMsg.message = '请求超时'
      errorMsg.code = 408
      break

    case 500:
      errorMsg.message = '请求出错,服务器内部错误'
      errorMsg.code = 500
      break

    case 501:
      errorMsg.message = '服务未实现'
      errorMsg.code = 501
      break

    case 502:
      errorMsg.message = '网关错误'
      errorMsg.code = 402
      break

    case 503:
      errorMsg.message = '服务不可用'
      errorMsg.code = 503
      break

    case 504:
      errorMsg.message = '网关超时'
      errorMsg.code = 504
      break

    case 505:
      errorMsg.message = 'HTTP版本不受支持'
      errorMsg.code = 505
      break
    default:
      errorMsg.message = '未知错误'
      errorMsg.code = 9999
  }

  return errorMsg
}

const handlerJumpErroPage = (code) => {
  // let error = handlerResponseErrorStatus(code)
  // if (code === '9999') {
  //   router.replace('/500')
  // }
  // if (code === '401') {
  //   store.dispatch('sys/handlerLoginOut').then((res) => {
  //     sessionStorage.setItem('isLogin', false)
  //     sessionStorage.setItem('TOKEN', '')
  //     sessionStorage.setItem('REFRESHTOKEN', '')
  //     sessionStorage.setItem('TOKENHEAD', '')
  //     sessionStorage.setItem('userInfo', '')
  //     Message.error('登录失效，请重新登录')
  //     location.href = '/'
  //   })
  // }
}

export default serve
