/**
 * 接口配置
 */

/**
 * 服务器地址
 */
export const SERVER_URL = {
  DEV_URL: 'https://api.yndth.tech/ds/ruili/kjds/exp/v1',
  PRO_URL: 'https://api.yndth.net/ds/ruili/kjds/exp/v1'
};

/**
 * 后台业务编码
 */
export const SERVER_CODE = {
  CODE_200: '200' // 成功请求
  // TODO 其它业务编码待补充
};
