import http from '@/utils/http.js'

// 购物车商品列表（分页）
export const getCartsList = (params) => {
  return http.get('/order/carts', {
    params: params
  })
}

// 商修改购物车商品信息（购买数量、SKU规格）
export const modifyCart = (params) => {
  return http.put(`/order/carts/${params.id}`, params)
}

// 移除购物车商品（批量）
export const deleteCarts = (params) => {
  // var a = (JSON.stringify(params))
  return http.delete('/order/carts', { data: params })
}
// 清空购物车
export const clearCarts = () => {
  return http.post('/order/carts/clear')
}

// 根据购物车信息生成确认单信息(对应APP【结算】操作)
export const confirmCarts = (params) => {
  return http.post('/order/carts/confirm', params)
}

// 立即购买:生成确认单信息到【确认订单】页
export const confirmOrder = (params) => {
  return http.post('/order/confirm', params)
}

// 提交订单
export const submitOrder = (params) => {
  return http.post('/order/submit', params)
}
// 通过订单状态获取订单列表，
export const getOrderList = (params) => {
  return http.get('/order', {
    params: params
  })
}
// 查询订单详情
export const getOrderDetail = (params) => {
  return http.get(`/order/${params.id}`)
}
// 删除订单
export const deleteOrder = (params) => {
  console.log(params)
  return http.delete('/order', { data: params })
}
// 确认收货订单
export const receiveOrder = (params) => {
  return http.post(`/order/${params.id}/receive`)
}
// 取消订单
export const cancelOrder = (params) => {
  return http.post(`/order/${params.id}/cancel`, params)
}
// 获取购物车SKU
export const getGoodsOptions = (params) => {
  return http.get(`/goods/mobile/goods/options/${params.id}`)
}

// 订单支付下单（银行卡）（单个+批量)
export const bankPay = (params) => {
  return http.post(`/order/pay/${params.bankCardId}`, params)
}

// 订单微信PC Native支付-返回扫码付二维码连接
export const wxPay = (params) => {
  return http.post('order/weixNativePay', params)
}

// 订单微信支付状态查询
export const getPayStatus = (params) => {
  return http.get('/order/pay/payStatus', {
    params: params
  })
}

// 通过物流单号查询物流跟踪信息
export const getLogistics = (params) => {
  return http.get('/order/logistics/sn', {
    params: params
  })
}

// 评论列表
export const getCommentList = (params) => {
  return http.get(`/goods/mobile/comment/${params.id}`, {
    params: params
  })
}
// 子评论列表
export const getSubCommentList = (params) => {
  return http.get(`/goods/mobile/comment/subComment/${params.commentId}`, {
    params: params
  })
}
// 添加评论
export const addComment = (params) => {
  return http.post('/goods/mobile/comment', params)
}
// 用户追加评论
export const followComment = (params) => {
  return http.post('/goods/mobile/comment/follow', params)
}

// 子评论新增
export const addSubComment = (params) => {
  return http.post(
    `/goods/mobile/comment/subComment/${params.commentId}`,
    params
  )
}

// 对评论进行点赞
export const commentLike = (params) => {
  return http.put(
    `/goods/mobile/comment/like/${params.commentId}?like=${params.like}`
  )
}

// 退货申请
export const returnedPurchase = (params) => {
  return http.post('/order/afterSale', params)
}

// 取消退货
export const cancelReturnedPurchase = (id) => {
  return http.post(`/order/return/${id}/cancel`)
}

// 申请的售后列表
export const afterSalesList = (params) => {
  return http.get('/order/afterSale', {
    params: params
  })
}

// 买家寄退货商品
export const returnGooods = (params) => {
  return http.post('/order/afterSale/returnGoods', params)
}
// 物流委托详情
export const getLogisticsDelegate = (params) => {
  // http.config.loading = true;
  return http.get(`/order/logistics/delegate/${params.sn}`)
}
// 查询退货详情
export const getOrderAfterSaleDetail = (params) => {
  // http.config.loading = true;
  return http.get(`/order/afterSale/detail/${params.sn}`)
}
