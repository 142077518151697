<template>
	<div class="mt-10 list-pages">
		<Page
			:total="total"
			show-total
			show-elevator
			show-sizer
			:page-size="pageSize"
			:current="current"
			:page-size-opts="pageSizeOpts"
			size="small"
			@on-change="handlePageChange"
			@on-page-size-change="handlePageSizeChange"
      placement="top"
		/>
	</div>
</template>
<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 20
    },
    pageSizeOpts: {
      type: Array,
      default () {
        return [10, 20, 30, 40, 50, 100]
      }
    },
    total: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
    };
  },
  methods: {
    handlePageChange (page) {
      this.$emit('on-change', page);
    },
    handlePageSizeChange (size) {
      this.$emit('on-page-size-change', size);
    }
  }
};
</script>
<style lang="scss" scoped>
.list-pages {
	text-align: center;
}
</style>
